import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { RestApiService } from '../services/api/rest-api.service';
import {
  ET0Model,
  InvitationsDataModel,
  InvitationsPendingDataModel,
  LanguagesDataModel,
  LayoutDataModel,
  SidebarDataModel,
} from './app-commons.model';

@Injectable({
  providedIn: 'root',
})
export class AppCommonsService {
  private restApiService: RestApiService;
  constructor(restApiService: RestApiService) {
    this.restApiService = restApiService;
  }
  public getMenu(): Observable<SidebarDataModel> {
    return new Observable((observer) => {
      this.restApiService.getMenu().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getLayout(): Observable<LayoutDataModel> {
    return new Observable((observer) => {
      this.restApiService.getLayout().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getMyUCROPTranslations(): Observable<any> {
    return new Observable((observer) => {
      this.restApiService.getMyUCROPTranslations().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getLanguages(): Observable<LanguagesDataModel[]> {
    return new Observable((observer) => {
      this.restApiService.getLanguages().subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public putInvitationResponse(vid, data): Observable<InvitationsDataModel> {
    return new Observable((observer) => {
      this.restApiService.putInvitationResponse(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public putResendEmail(vid, data): Observable<InvitationsDataModel> {
    return new Observable((observer) => {
      this.restApiService.putResendEmail(vid, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
}
