import * as moment from 'moment';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import Swal from 'sweetalert2';

export const getBreadcrumbText = (type: string, data: string) => {
  let queryParam = '';
  let queryName = '';

  if (type === 'program') {
    queryParam = data;
    queryName = 'Programa';
  }

  if (type === 'groups') {
    queryParam = data;
    queryName = 'Agrupación';
  }

  if (type === 'fertilizer_formula') {
    queryParam = data;
    queryName = 'Receta de abono';
  }

  if (type === 'drainages_v3' || type === 'drainages_v4') {
    queryParam = data;
    queryName = 'Drenaje';
  }

  if (type === 'mixture_formulas') {
    queryParam = data;
    queryName = 'Receta de mezcla';
  }

  if (type === 'meteo_alarms') {
    queryParam = data;
    queryName = 'Alarma';
  }

  return { queryParam, queryName };
};

export const secondsInputToDate = (seconds: number) => {
  // const date = moment(seconds * 1000).toDate();
  // return date;

  const date = moment(
    seconds * 1000 + new Date(seconds).getTimezoneOffset() * 60000
  ).toDate();

  return date;
};

export const convertFormattedTimeToSeconds = (hms) => {
  const a = hms.split(':');
  return +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
};

export const convertMMSSFormattedTimeToSeconds = (mmss: string) => {
  const a = mmss.split(':');
  return +a[0] * 60 + +a[1];
};

export const DateToSecondsInput = (date: Date) => {
  const time = date.toTimeString();
  // @ts-ignore
  const seconds = time.substring(6, 8) * 1;
  // @ts-ignore
  const minutes = time.substring(3, 5) * 60;
  // @ts-ignore
  const hours = time.substring(0, 2) * 3600;
  return hours + minutes + seconds;
};

export const removeDirtyInputs = (myForm: UntypedFormGroup) => {
  myForm.markAsUntouched();
  myForm.markAsPristine();

  const dirtyInputs = document.querySelectorAll('input');
  const dirtySelects = document.querySelectorAll('select');
  const forms = document.querySelectorAll('form');

  dirtyInputs.forEach((elem) => {
    if (elem.classList.contains('ng-dirty')) {
      elem.classList.remove('ng-dirty');
    }

    if (elem.classList.contains('ng-touched')) {
      elem.classList.remove('ng-touched');
    }
  });

  dirtySelects.forEach((elem) => {
    if (elem.classList.contains('ng-dirty')) {
      elem.classList.remove('ng-dirty');
    }

    if (elem.classList.contains('ng-touched')) {
      elem.classList.remove('ng-touched');
    }
  });

  forms.forEach((elem) => {
    if (elem.classList.contains('ng-dirty')) {
      elem.classList.remove('ng-dirty');
    }

    if (elem.classList.contains('ng-touched')) {
      elem.classList.remove('ng-touched');
    }
  });
};

// This is a function to test forms. Don't delete it!
export const checkInvalidFormInput = (form: UntypedFormGroup) => {
  for (const controlName in form.controls) {
    if (form.controls.hasOwnProperty(controlName)) {
      const control = form.controls[controlName];
      if (control.invalid) {
        console.log(`El campo ${controlName} es inválido`);
      }
    }
  }
};

export const stepDispatchInputEvent = (input, type: string) => {
  if (type === 'up') {
    input.stepUp();
    input.dispatchEvent(new Event('input'));
  }

  if (type === 'down') {
    input.stepDown();
    input.dispatchEvent(new Event('input'));
  }
};

export const saveSubmitWithTerminalStatus = (
  res,
  showModal: boolean,
  connected: boolean,
  errorText: string,
  successText: string,
  confirmButtonText: string,
  disconnectedText: string
) => {
  if (res.error) {
    if (showModal) {
      Swal.fire({
        text: errorText,
        showConfirmButton: true,
        confirmButtonText,
        icon: 'error',
      });
    }
  } else {
    if (showModal) {
      if (!connected) {
        Swal.fire({
          text: disconnectedText,
          showConfirmButton: true,
          confirmButtonText,
          icon: 'error',
        });
      } else {
        Swal.fire({
          text: successText,
          showConfirmButton: true,
          confirmButtonText,
          icon: 'success',
        });
      }
    }
  }
};

export const saveDataAndShowModal = (
  res,
  showModal: boolean,
  errorText: string,
  successText: string,
  confirmButtonText: string
) => {
  if (res.error) {
    if (showModal) {
      Swal.fire({
        text: errorText,
        showConfirmButton: true,
        confirmButtonText,
        icon: 'error',
      });
    }
  } else {
    if (showModal) {
      Swal.fire({
        text: successText,
        showConfirmButton: true,
        confirmButtonText,
        icon: 'success',
      });
    }
  }
};

export const getCardinalDirection = (angle) => {
  const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
  return directions[Math.round(angle / 45) % 8];
};

export const disableEndTimeInput = (form: UntypedFormGroup) => {
  // Disable endTime inputs due to David's comment on this Trello card (https://trello.com/c/bq4vufN8/735-clima-revisi%C3%B3n-de-programaci%C3%B3n)
  const periodsControl = form.get('periods');
  if (periodsControl instanceof FormArray) {
    periodsControl.controls.forEach((period) => {
      period.get('endTime').disable();
    });
  }
};
