import { Component, OnInit, Input } from '@angular/core';
import { AppCommonsService } from '../../app-commons.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { PlatformLibService } from '@nutricontrol/app360-shared';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-bottom-tabs',
  templateUrl: './bottom-tabs.component.html',
  styleUrls: ['./bottom-tabs.component.scss'],
})
export class BottomTabsComponent implements OnInit {
  @Input() isMenuOpen = false;
  menu;
  ready = false;
  fix20pxMarginTop = false;
  currentRoute = '';

  constructor(
    private commonsService: AppCommonsService,
    private platformLib: PlatformLibService,
    public translationsLib: TranslationsLibService,
    private router: Router
  ) {}

  ngOnInit() {
    this.fix20pxMarginTop = this.platformLib.isPad();
    this.commonsService.getMenu().subscribe((res) => {
      this.menu = res.layout_configuration.bottom_tabs;
      this.ready = true;
    });

    this.currentRoute = this.router.url;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentRoute = event.urlAfterRedirects;
      });
  }
}
