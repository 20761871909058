import { Component, OnInit } from '@angular/core';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit {
  language: string;

  constructor(
    private sessionLib: SessionLibService,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit(): void {
    this.sessionLib.getLanguage();
    this.language = this.sessionLib.getLanguage();
    if (!this.language) this.language = 'es';
  }

  changeLanguage(language: string) {
    this.sessionLib.setLanguage(language);
    window.location.reload();
  }
}
