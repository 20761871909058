import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toHex',
})
export class ToHexPipe implements PipeTransform {
  transform(value) {
    const v = Number(value);
    if (!Number.isNaN(v)) return v.toString(16);
    return value;
  }
}
