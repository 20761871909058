import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
@Injectable({
  providedIn: 'root',
})
export class LegalAgreementsLibService {
  constructor(public translationsLib: TranslationsLibService) {}

  async showLegalAdvice() {
    await Browser.open({
      url: this.translationsLib.get('configuration_legal_advice_url'),
    });
  }
  async showPrivacyPolicy() {
    await Browser.open({
      url: this.translationsLib.get('configuration_privacy_policy_url'),
    });
  }
  async showCookiePolicy() {
    await Browser.open({
      url: this.translationsLib.get('configuration_cookie_policy_url'),
    });
  }
}
