import { Component, OnInit } from '@angular/core';
import { AppCommonsService } from '../../app-commons.service';
import { Router } from '@angular/router';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { SessionLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  menu;
  ready = false;

  constructor(
    private sessionLib: SessionLibService,
    private commonsService: AppCommonsService,
    private router: Router,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {
    this.commonsService.getMenu().subscribe((res) => {
      this.menu = res.layout_configuration.bottom_tabs;
      // Avoid accessing to MyUCROP Billing when not allowed
      if (this.menu.length <= 1) {
        if (this.router.url.indexOf('modules') < 0) {
          document.location.href = '/modules';
        }
      } else {
        const home = String(res.layout_configuration.home[0].page);
        this.sessionLib.setHome(home);
      }
      this.ready = true;
    });
  }
  checkActive(page) {
    return this.router.url.split('/')[1] === page;
  }
}
