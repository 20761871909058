import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sanitizeText',
})
export class SanitizeTextPipe implements PipeTransform {
  transform(text: string): string {
    text = text.replace(/<[^>]*>/g, '');

    text = text.replace(/&nbsp;|&#160;|&#xa0;/gi, '');

    return text;
  }
}
