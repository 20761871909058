import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AppCommonsService } from '../../app-commons.service';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { IonicModule } from '@ionic/angular';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { PlatformLibService } from '@nutricontrol/app360-shared';
import Swal from 'sweetalert2';

@Component({
  standalone: true,
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  imports: [
    RouterModule,
    IonicModule,
    MatExpansionModule,
    NgForOf,
    NgIf,
    NgClass,
  ],
})
export class SidebarComponent implements OnInit {
  public appPages;
  public instalatorMenu = [];
  isNative = false;
  constructor(
    public router: Router,
    private commonsService: AppCommonsService,
    private session: SessionLibService,
    private route: ActivatedRoute,
    public platformLib: PlatformLibService
  ) {}

  ngOnInit() {
    this.isNative = this.platformLib.isNative();
    this.commonsService.getMenu().subscribe((response) => {
      this.appPages = response.layout_configuration.upper_menu;
      this.appPages = Object.values(this.appPages);
      this.session.setHome(response.layout_configuration.home[0].page);
    });
  }

  goHome() {
    const home = String(this.session.getHome());
    location.replace(home);
  }
}
