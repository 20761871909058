import { Component, SimpleChange, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { OnChanges } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent implements OnChanges {
  @Input() public passwordToCheck: string;
  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;
  msg = '';
  private colors = ['darkred', 'orangered', 'orange', 'yellowgreen'];
  constructor(
    private fb: FormBuilder,
    public translationsLib: TranslationsLibService
  ) {}
  checkStrength(p) {
    // 1
    let force = 0;

    // 2
    const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);

    // 3
    const flags = [lowerLetters, upperLetters, numbers, symbols];

    // 4
    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    // 5
    force += 2 * p.length + (p.length >= 10 ? 1 : 0);
    force += passedMatches * 10;

    // 6
    force = p.length <= 3 ? Math.min(force, 10) : force;

    // 7
    force = passedMatches === 1 ? Math.min(force, 10) : force;
    force = passedMatches === 2 ? Math.min(force, 20) : force;
    force = passedMatches === 3 ? Math.min(force, 30) : force;
    force = passedMatches === 4 ? Math.min(force, 40) : force;

    return force;
  }
  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    const password = changes.passwordToCheck.currentValue;
    this.setBarColors(4, '#DDD');
    if (password) {
      const c = this.getColor(this.checkStrength(password));
      this.setBarColors(c.index, c.color);
      switch (c.index) {
        case 1:
          this.msg = this.translationsLib.get('auth_password_strengh_poor');
          break;
        case 2:
          this.msg = this.translationsLib.get('auth_password_strengh_bad');
          break;
        case 3:
          this.msg = this.translationsLib.get('auth_password_strengh_good');
          break;
        case 4:
          this.msg = this.translationsLib.get(
            'auth_password_strengh_very_good'
          );
          break;
      }
    } else {
      this.msg = '';
    }
  }
  getStrength(pass) {
    const c = this.getColor(this.checkStrength(pass));
    return c.index;
  }
  private getColor(s) {
    let index = 0;
    if (s === 10) {
      index = 0;
    } else if (s === 20) {
      index = 1;
    } else if (s === 30) {
      index = 2;
    } else if (s === 40) {
      index = 3;
    } else {
      index = 4;
    }
    return {
      index: index + 1,
      color: this.colors[index],
    };
  }

  private setBarColors(count, col) {
    for (let n = 0; n < count; n++) {
      this['bar' + n] = col;
    }
  }
}
