import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { SessionLibService } from '@nutricontrol/app360-shared';

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {
  private response = false;
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate() {
    await this.callService();
    if (this.response === false) {
      await this.router.navigate(['/']);
      return false;
    }
    return true;
  }

  callService() {
    return new Promise((resolve, reject) => {
      this.authService.checkAuth().subscribe(
        (res) => {
          this.response = true;
          resolve(res);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }
}
