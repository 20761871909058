// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backend: 'https://api-gw.ucrop.dev',
  language: 'en',
  google_maps_api: 'AIzaSyCrzZWsKiBzYcMI3PPuENCXBYa1Hfa1Cgo',
  google_maps_api_android: 'AIzaSyCJQl3fML2VigfChPRrooelDgrBrEK5VPM',
  google_maps_api_ios: 'AIzaSyBqFxfwaxKtwFoCfnnrBD2HaaM-_Xy08HU',
  google_maps_static_api: 'AIzaSyAeVz5BLk8oawep9Hmm6XE28O9enpJduYc',
  frontend: 'https://my.ucrop.dev/',
  timeStamp: '28/10/2024 10:20:14',
  stripe_api:
    'pk_test_51MSFPSIckW40TMhSyJ54uVNJoLAbX94GU8VgZyHHXvXi6CV4jTP3FHvpGRaLY1Cm6dgnnGlZfmUoNCR8TMBxY1hi00Nu7kBTuu',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
