import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(array: Array<any>, field?: string): Array<any> {
    array.sort((a, b) => {
      if (field && a[field] && b[field]) {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a > b ? 1 : -1;
      }
    });
    return array;
  }
}
