import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-graphic-header',
  templateUrl: './graphic-header.component.html',
  styleUrls: ['./graphic-header.component.scss'],
})
export class GraphicHeaderComponent implements OnInit {
  @Input() graphic: any;
  @Input() viewEmitMode;
  @Output() viewModeEvent = new EventEmitter<any>();
  end;
  start
  constructor(
    public translationsLib: TranslationsLibService,
  ) {}

  ngOnInit() {
    console.log(this.graphic.dates.value, this.graphic.dates);
    this.start = moment(
      this.graphic.dates.value.start
    ).format('DD/MM/YYYY HH:mm:ss');
    this.end = moment(this.graphic.dates.value.end).format(
      'DD/MM/YYYY HH:mm:ss'
    );
  }
  changeMode(){
    const button = document.getElementById('edit');
    this.viewEmitMode = !this.viewEmitMode;
    if(this.viewEmitMode === true){
      button.style.backgroundColor = '#66B8BE';
      button.style.color = '#FFFFFF';
    }
    else{
      button.style.backgroundColor = '#edf2f9';
      button.style.color = '#224261';
    }
    this.viewModeEvent.emit(this.viewEmitMode);
  }
}
