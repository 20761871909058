import { Pipe, PipeTransform } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';

@Pipe({
  name: 'alarmGridTag',
})
export class AlarmGridTagPipe implements PipeTransform {
  constructor(public translationsLib: TranslationsLibService) {}

  transform(field: any, type: string): string {
    if (type === 'configurable')
      return this.translationsLib.get('alarms_configurable_' + field.value.tag);

    return this.translationsLib.get('alarms_irrigation_' + field.value.tag);
  }
}
