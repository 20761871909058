import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { SessionLibService } from '@nutricontrol/app360-shared';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-password-reset-user-invitation',
  templateUrl: './password-reset-user-invitation.component.html',
  styleUrls: ['./password-reset-user-invitation.component.scss'],
})
export class PasswordResetUserInvitationComponent implements OnInit {
  resetUserInvitation = 1;
  private code = '';
  private security_hash = '';
  private email = '';
  private fb: FormBuilder = new FormBuilder();
  ready = false;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  resetPasswordPass = this.fb.group({
    password: ['', Validators.required],
    confirmation_code: [''],
    email: [''],
    security_hash: [''],
  });

  constructor(
    private authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private sessionLib: SessionLibService,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if ('email' in params) {
        this.email = params.email;
      }
      if ('security_hash' in params) {
        this.security_hash = params.security_hash;
      }
      this.ready = true;
    });
  }

  setConfirmationCode(code) {
    this.resetUserInvitation = 2;
    this.code = code;
  }
  onSubmitPassword() {
    this.resetPasswordPass.value.confirmation_code = this.code;
    this.resetPasswordPass.value.email = this.email;
    this.resetPasswordPass.value.security_hash = this.security_hash;
    this.authService
      .resetPasswordNewPassword(this.resetPasswordPass.value)
      .subscribe(
        (response) => {
          this.authService
            .login({
              email: this.email,
              password: this.resetPasswordPass.value.password,
            })
            .subscribe((response) => {
              this.authService.doLogin(response);

              const home = String(this.sessionLib.getHome());
              location.replace(home);
            });
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: this.translationsLib.get('error'),
            text: this.translationsLib.get(
              'auth_forgotten_error_resetting_password'
            ),
            confirmButtonColor: '#224261',
          });
        }
      );
  }
}
