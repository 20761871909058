import { Injectable } from '@angular/core';
import { AppCommonsService } from '../../commons/app-commons.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class CheckConnectionLibService {
  constructor(private commonService: AppCommonsService) {}
  public initialize(): void {
    let reconnected = false;
    setInterval(() => {
      if (!navigator.onLine && reconnected !== true) {
        reconnected = true;
        setTimeout(() => {
          if (!navigator.onLine) {
            this.alert();
          }
        }, 10000);
      } else {
        if (reconnected === true && navigator.onLine) {
          reconnected = false;
          Swal.close();
        }
      }
    }, 10000);
  }

  alert() {
    Swal.fire({
      /*icon: 'error',*/
      didOpen: () => {
        Swal.showLoading(null);
      },
      title: '<h2>Oops...</h2>',
      text: 'Esta aplicación necesita conexión a Internet. Por favor, revise su conexión para poder continuar usándola',
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
    });
  }
}
