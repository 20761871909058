import { Component, Input, OnInit } from '@angular/core';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { AppCommonsService } from '../../app-commons.service';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit {
  @Input() name = '';
  public avatar;
  public breadcrumb = '';
  constructor(
    private session: SessionLibService,
    public router: Router,
    private title: Title,
    private route: ActivatedRoute,
    private commonsService: AppCommonsService,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit() {
    this.avatar = this.session.getAvatar();
    if (document.location.href.includes('breadcrumb')) {
      let link = document.location.href;
      link = link.split('=')[1];
      this.breadcrumb = this.translationsLib.get(link);
    }
    this.router.events.subscribe((val) => {
      if (document.location.href.includes('breadcrumb')) {
        let link = document.location.href;
        link = link.split('=')[1];
        this.breadcrumb = this.translationsLib.get(link);
      } else {
        this.breadcrumb = '';
      }
      if (val instanceof ActivationEnd) {
        let bc_key = val.snapshot.data.title;
        if (bc_key === 'billing-addresses') {
          bc_key = 'billing_addresses';
        }
        this.name = this.translationsLib.get(bc_key);
        if (this.breadcrumb !== '') {
          this.title.setTitle(
            'MyUCROP | ' + this.name + ' | ' + this.breadcrumb
          );
        } else {
          this.title.setTitle('MyUCROP | ' + this.name);
        }
      }
    });
    const r = this.router.config.find(
      (route) => route.path === this.router.url.split('/')[1]
    );
    this.name = this.translationsLib.get(r.data.title);
    if (this.breadcrumb !== '') {
      this.title.setTitle('MyUCROP | ' + this.name + ' | ' + this.breadcrumb);
    } else {
      this.title.setTitle('MyUCROP | ' + this.name);
    }
  }
}
